// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-pages-work-biblioapp-js": () => import("./../../../src/pages/work/biblioapp.js" /* webpackChunkName: "component---src-pages-work-biblioapp-js" */),
  "component---src-pages-work-box-8-js": () => import("./../../../src/pages/work/box8.js" /* webpackChunkName: "component---src-pages-work-box-8-js" */),
  "component---src-pages-work-enchanting-travels-js": () => import("./../../../src/pages/work/enchanting-travels.js" /* webpackChunkName: "component---src-pages-work-enchanting-travels-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-wow-club-js": () => import("./../../../src/pages/work/wow-club.js" /* webpackChunkName: "component---src-pages-work-wow-club-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tag-list-js": () => import("./../../../src/templates/tag-list.js" /* webpackChunkName: "component---src-templates-tag-list-js" */)
}

